import React, { useRef } from 'react';
import { Button, Title, Link, Tooltip } from '@nerdwallet/currency';

import Image from 'next/image';
import SegmentImpression from '../../segment-impression';
import useAnalytics from '~/app/lib/segment/useAnalytics';
import { HOMEPAGE_SEGMENT_INSTRUMENTATION } from '~/app/lib/analytics/analytics-constants';
import styles from './nw-plus-hero.module.scss';
import { NwPlusHeroCarousel } from './nw-plus-hero-carousel';
import { NwPlusCard } from '~/app/lib/front-page-content-src/nw-plus-hero-content';
import useOnScreen from '~/app/lib/use-on-screen';
import { NwPlusStickyBanner } from './nw-plus-sticky-banner';

interface Props {
  backgroundColor: string;
  subtitle: string;
  ctaButtonText: string;
  ctaLinkUrl: string;
  visualBackgroundPiece: string;
  visualBackgroundImageDesktopUrl: string;
  visualBackgroundImageTabletUrl: string;
  visualBackgroundImageMobileUrl: string;
  visualBackgroundImageAlt: string;
  nwplusFullLogo: string;
  nwplusFullLogoAlt: string;
  trkQueryParam: string;
  trkQueryParamSticky: string;
  nwplusSmallLogo: string;
  stickyBannerText: string;
  stickyBannerCtaText: string;
  stickyBannerCtaLink: string;
  cards: NwPlusCard[];
}

export const NwPlusHero = ({
  backgroundColor,
  subtitle,
  ctaButtonText,
  ctaLinkUrl,
  visualBackgroundPiece,
  visualBackgroundImageDesktopUrl,
  visualBackgroundImageTabletUrl,
  visualBackgroundImageMobileUrl,
  visualBackgroundImageAlt,
  nwplusFullLogo,
  nwplusFullLogoAlt,
  nwplusSmallLogo,
  trkQueryParam,
  trkQueryParamSticky,
  stickyBannerCtaLink,
  stickyBannerText,
  stickyBannerCtaText,
  cards,
}: Props) => {
  const { trackSegmentTrackEvent } = useAnalytics();
  const handleCampaignLinkClick = () => {
    trackSegmentTrackEvent(
      'Element Interaction',
      HOMEPAGE_SEGMENT_INSTRUMENTATION.campaign,
    );
  };
  const elementRef = useRef<HTMLDivElement>(null);
  const isNWPlusHeroOnScreen = useOnScreen(elementRef, true);
  const backgroundStyle = {
    backgroundImage: `url(${visualBackgroundPiece})`,
    backgroundPosition: 'bottom center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: backgroundColor,
  }

  return (
    <SegmentImpression
      eventName="Element Impression"
      eventProps={{
        entity_name: HOMEPAGE_SEGMENT_INSTRUMENTATION.campaign.sub_location,
        location: HOMEPAGE_SEGMENT_INSTRUMENTATION.campaign.location,
      }}
    >
      <div
        className={styles.container}
        style={backgroundStyle}
      >
        <div className={styles.nwplusHero}>
          <div className={styles.nwplusHeroCta}>
            <Link
              href={`${ctaLinkUrl}?trk=${trkQueryParam}`}
              onClick={handleCampaignLinkClick}
              className={styles.nwplusHeroNWPlusFullLogo}
            >
              <Image
                src={nwplusFullLogo}
                alt={nwplusFullLogoAlt}
                width="302"
                height="44"
                layout="responsive"
                style={{ objectFit: 'cover' }}
                quality={100}
              />
            </Link>
            <Title size="small" className={styles.nwplusHeroCtaTitle}>
              Get up to{' '}
              <span className={styles.nwplusHeroCtaTitlePre}>
                $425/year{' '}
              </span>
              in{' '}
              <span className={styles.nwplusHeroCtaTitlePre}>
                rewards and savings{' '}
              </span>
              with the new{' '}
              <span className={styles.nwplusHeroCtaTitlePre}>
                NerdWallet+
              </span>
            </Title>
            <div className={styles.nwplusHeroCtaText}>
              <Tooltip
                label={subtitle}
                content="$425 value is based on a combination of the maximum $253 in rewards and a maximum of $180 savings on exclusive offers like ID Theft protection. Value does not include potential Treasury earnings or insurance assistant savings. Offers may expire or change without notice."
              />
            </div>
            <Button
              href={`${ctaLinkUrl}?trk=${trkQueryParam}`}
              primary
              className={styles.nwplusHeroCtaButton}
              onClick={handleCampaignLinkClick}
              ref={elementRef}
            >
              {ctaButtonText}
            </Button>
          </div>
          <Link
            href={`${ctaLinkUrl}?trk=${trkQueryParam}`}
            onClick={handleCampaignLinkClick}
            className={styles.nwplusHeroVisualContainerDesktop}
          >
            <Image
              src={visualBackgroundImageDesktopUrl}
              alt={visualBackgroundImageAlt}
              width="590"
              height="418"
              layout="responsive"
              style={{ objectFit: 'contain' }}
              quality={100}
            />
          </Link>
          <Link
            href={`${ctaLinkUrl}?trk=${trkQueryParam}`}
            onClick={handleCampaignLinkClick}
            className={styles.nwplusHeroVisualContainerTablet}
          >
            <div className={styles.nwplusHeroVisualContainerTablet}>
              <Image
                src={visualBackgroundImageTabletUrl}
                alt={visualBackgroundImageAlt}
                width="768"
                height="555"
                layout="responsive"
                style={{ objectFit: 'contain' }}
                quality={100}
              ></Image>
            </div>
          </Link>
          <Link
            href={`${ctaLinkUrl}?trk=${trkQueryParam}`}
            onClick={handleCampaignLinkClick}
            className={styles.nwplusHeroVisualContainerMobile}
          >
            <div className={styles.nwplusHeroVisualContainerMobile}>
              <Image
                src={visualBackgroundImageMobileUrl}
                alt={visualBackgroundImageAlt}
                width="375"
                height="318"
                layout="responsive"
                style={{ objectFit: 'contain' }}
                quality={100}
              ></Image>
            </div>
          </Link>
        </div>
        <NwPlusHeroCarousel cards={cards} />
      </div>

      <NwPlusStickyBanner
        ctaLinkUrl={stickyBannerCtaLink}
        nwplusFullLogoAlt={nwplusFullLogoAlt}
        nwplusSmallLogo={nwplusSmallLogo}
        showStickyBanner={!isNWPlusHeroOnScreen}
        stickyBannerCtaText={stickyBannerCtaText}
        stickyBannerText={stickyBannerText}
        trkQueryParamSticky={trkQueryParamSticky}
      />
    </SegmentImpression>
  );
};
