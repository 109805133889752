import React from 'react';
import PropTypes from 'prop-types';
import { Button, Text, Link } from '@nerdwallet/currency';
import classNames from 'classnames';
import Image from 'next/image';
import SegmentImpression from '../../segment-impression';
import useAnalytics from '~/app/lib/segment/useAnalytics';
import { HOMEPAGE_SEGMENT_INSTRUMENTATION } from '~/app/lib/analytics/analytics-constants';
import styles from './campaign-highlight.module.scss';

const CampaignHighlight = ({
  backgroundColor,
  title,
  titleEnd,
  titlePreColor,
  titleEndColor,
  subtitle,
  ctaButtonText,
  ctaLinkUrl,
  campaignHighlightFooterCopy,
  campaignHighlightFooterCopyColor,
  visualBackgroundImageDesktopUrl,
  visualBackgroundImageMidUrl,
  visualBackgroundImageTabletUrl,
  visualBackgroundImageMobileUrl,
  visualBackgroundImageAlt,
  nwplusFullLogo,
  nwplusFullLogoAlt,
  nwplusSmallLogo,
  trkQueryParam,
}) => {
  const titlePreStyle = {
    color: `${titlePreColor}`,
    fontWeight: 700,
  };

  const titleEndStyle = {
    color: `${titleEndColor}`,
  };

  const campaignHighlightFooterEndStyle = {
    color: `${campaignHighlightFooterCopyColor}`,
  };

  const { trackSegmentTrackEvent } = useAnalytics();
  const handleCampaignLinkClick = () => {
    trackSegmentTrackEvent(
      'Element Interaction',
      HOMEPAGE_SEGMENT_INSTRUMENTATION.campaign,
    );
  };

  return (
    <SegmentImpression
      eventName="Element Impression"
      eventProps={{
        entity_name: HOMEPAGE_SEGMENT_INSTRUMENTATION.campaign.sub_location,
        location: HOMEPAGE_SEGMENT_INSTRUMENTATION.campaign.location,
      }}
    >
      <div className={styles.campaignHighlight}>
        <div
          className={styles.campaignHighlightCta}
          style={{ backgroundColor }}
        >
          <Text
            bold
            size="large"
            component="h2"
            className={styles.campaignHighlightCtaTitle}
          >
            <span className={styles.campaignHighlightCtaTitlePre}>
              <Image
                imageClassName={styles.nwplusSmallLogo}
                className={styles.nwplusSmallLogo}
                backgroundColor="none"
                src={nwplusSmallLogo}
                width="41"
                height="22"
              />
            </span>
            <span style={titlePreStyle}>{title}</span>
            <span
              className={styles.campaignHighlightCtaTitleEnd}
              style={titleEndStyle}
            >
              <sup>{titleEnd}</sup>
            </span>
          </Text>
          <div className={styles.campaignHighlightCtaText}>{subtitle}</div>
          <Button
            href={`${ctaLinkUrl}?trk=${trkQueryParam}`}
            primary
            className={styles.campaignHighlightCtaButton}
            onClick={handleCampaignLinkClick}
          >
            {ctaButtonText}
          </Button>
        </div>
        <Link
          href={`${ctaLinkUrl}?trk=${trkQueryParam}`}
          onClick={handleCampaignLinkClick}
          className={styles.campaignHighlightVisualContainerDesktop}
        >
          <Image
            src={visualBackgroundImageDesktopUrl}
            alt={visualBackgroundImageAlt}
            width="562"
            height="260"
            layout="responsive"
            style={{ objectFit: 'cover', borderRadius: '0 16px 16px 0' }}
            quality={100}
          />
        </Link>
        <Link
          href={`${ctaLinkUrl}?trk=${trkQueryParam}`}
          onClick={handleCampaignLinkClick}
          className={styles.campaignHighlightVisualContainerMid}
        >
          <Image
            src={visualBackgroundImageMidUrl}
            alt={visualBackgroundImageAlt}
            width="514"
            height="296"
            layout="responsive"
            style={{ objectFit: 'cover', borderRadius: '0 16px 16px 0' }}
            quality={100}
          />
        </Link>
        <Link
          href={`${ctaLinkUrl}?trk=${trkQueryParam}`}
          onClick={handleCampaignLinkClick}
          className={styles.campaignHighlightVisualContainerTablet}
        >
          <Image
            src={visualBackgroundImageTabletUrl}
            alt={visualBackgroundImageAlt}
            width="366"
            height="320"
            layout="responsive"
            style={{ objectFit: 'cover', borderRadius: '0 16px 16px 0' }}
            quality={100}
          />
        </Link>
        <Link
          href={`${ctaLinkUrl}?trk=${trkQueryParam}`}
          onClick={handleCampaignLinkClick}
          className={styles.campaignHighlightVisualContainerMobile}
        >
          <div className={styles.campaignHighlightVisualContainerMobile}>
            <Image
              src={visualBackgroundImageMobileUrl}
              alt={visualBackgroundImageAlt}
              width="375"
              height="318"
              layout="responsive"
              style={{ objectFit: 'cover', borderRadius: '0 0 16px 16px' }}
              quality={100}
            ></Image>
          </div>
        </Link>
      </div>
    </SegmentImpression>
  );
};

CampaignHighlight.propTypes = {
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  titleEnd: PropTypes.string,
  titlePreColor: PropTypes.string,
  titleEndColor: PropTypes.string,
  subtitle: PropTypes.string,
  ctaButtonText: PropTypes.string,
  ctaLinkUrl: PropTypes.string,
  campaignHighlightFooterCopy: PropTypes.string,
  campaignHighlightFooterCopyColor: PropTypes.string,
  visualBackgroundImageDekstopUrl: PropTypes.string,
  visualBackgroundImageMidUrl: PropTypes.string,
  visualBackgroundImageTabletUrl: PropTypes.string,
  visualBackgroundImageMobileUrl: PropTypes.string,
  visualBackgroundImageAlt: PropTypes.string,
  nwplusFullLogo: PropTypes.string,
  nwplusFullLogoAlt: PropTypes.string,
  nwplusSmallLogo: PropTypes.string,
  trkQueryParam: PropTypes.string,
};

CampaignHighlight.displayName = 'CampaignHighlight';

export default CampaignHighlight;
