import { useMemo } from 'react';
import { useLottie, useLottieInteractivity } from 'lottie-react';

const LottieAnimation = (burst) => {
  // the animation data should be memorized, otherwise the tree state changes
  // will messed up lottie
  const animationData = useMemo(() => {
    return JSON.parse(burst?.burst.data);
  }, [burst?.burst.data]);

  const style = {
    height: burst?.burst.height,
    width: burst?.burst.width,
  };

  const options = {
    animationData,
  };

  const lottieObj = useLottie(options, style);
  const Animation = useLottieInteractivity({
    lottieObj,
    mode: 'scroll',
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    actions: [
      {
        visibility: [0.1, 1.0],
        type: 'seek',
        frames: [0, 240],
      },
    ],
  });

  return Animation;
};

export default LottieAnimation;
