import { HOMEPAGE as analyticsBase } from '../analytics/analytics-constants';

export type NwPlusCard = {
  icon: string;
  title: string;
  subtitle: string;
};

export interface NwPlus {
  subtitle: string;
  backgroundColor: string;
  ctaButtonText: string;
  ctaLinkUrl: string;
  visualBackgroundPiece: string;
  visualBackgroundImageDesktopUrl: string;
  visualBackgroundImageTabletUrl: string;
  visualBackgroundImageMobileUrl: string;
  visualBackgroundImageAlt: string;
  nwplusFullLogo: string;
  nwplusFullLogoAlt: string;
  nwplusSmallLogo: string;
  trkQueryParam: string;
  trkQueryParamSticky: string;
  stickyBannerCtaText: string;
  stickyBannerText: string;
  stickyBannerCtaLink: string;
  cards: NwPlusCard[];
}

const content: NwPlus = {
  subtitle:
    'It pays to be smarter with your finances. As a NerdWallet+ member, you can earn rewards for your smart financial decisions and save through exclusive access to financial products and tools, all for just $49/year. ',
  backgroundColor: '#E8FDAC',
  ctaButtonText: 'Learn More',
  ctaLinkUrl: 'lp/nerdwallet-plus-main',
  visualBackgroundPiece: `https://www.nerdwallet.com/cdn/apps/prod/front-page/images/homepage/desktop-hero-pattern-full.png`,
  visualBackgroundImageDesktopUrl: `/nw_plus_assets/nwplus-hero-image-v3.png`,
  visualBackgroundImageTabletUrl: `/nw_plus_assets/nwplus-hero-image-tablet.png`,
  visualBackgroundImageMobileUrl: `/nw_plus_assets/nwplus-hero-image-mobile-v2.png`,
  visualBackgroundImageAlt:
    'NerdWallet+ phone app showing 2,500 point rewards balance',
  nwplusFullLogo: `/nw_plus_assets/nwplus_full_logo_v2.png`,
  nwplusFullLogoAlt: 'NerdWallet Plus Logo',
  nwplusSmallLogo: `/nwplus_small_logo.png`,
  stickyBannerCtaText: 'JOIN NERDWALLET+ ',
  stickyBannerText: 'Start getting rewarded for your smart financial decisions',
  stickyBannerCtaLink: '/lp/nerdwallet-plus-main',
  trkQueryParamSticky: analyticsBase.entity_names.sticky_banner, // used to append to the URL when the sticky bammer button is clicked,
  cards: [
    {
      icon: '/nw_plus_assets/get_cash_rewards_icon_v2.png',
      title: 'Get cash rewards',
      subtitle:
        'Earn cash rewards for the smart financial moves you’re already making — and for making smart decisions with eligible products¹ on NerdWallet.',
    },
    {
      icon: '/nw_plus_assets/exclusive_access_icon_v2.png',
      title: 'Exclusive access to products and tools',
      subtitle:
        'From maximizing your cash to getting a lower insurance premium, our products and tools can help you make the most of your money.',
    },
    {
      icon: '/nw_plus_assets/ask_the_nerds_icon_v2.png',
      title: 'Ask the Nerds',
      subtitle:
        'Send our writers your questions about a range of financial topics, and they’ll get you an objective answer within two business days.',
    },
  ],
  trkQueryParam: analyticsBase.entity_names.hero_module, // used to append to the URL when the button is clicked,
};

export default content;
