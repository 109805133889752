import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from '@nerdwallet/base-styles/classes';
import Image from 'next/image';
import {
  useExperiment,
  Experiment,
  ExperimentControl,
  ExperimentVariant,
} from '@nerdwallet/features';
import { setTrk } from '@nerdwallet/nw-client-lib/trk-helper';

import { Box, Text, Button } from '@nerdwallet/currency';
import { EXPERIMENTS } from '~/app/experiments';
import { trackInteraction } from '~/app/lib/analytics/analytics-helpers';
import {
  HOMEPAGE as analyticsBase,
  HOMEPAGE_SEGMENT_INSTRUMENTATION,
} from '~/app/lib/analytics/analytics-constants';

import styles from './vertical-comparison.module.scss';

import useAnalytics from '~/app/lib/segment/useAnalytics';

const VerticalComparisonTab = ({
  id,
  subtitle,
  description,
  primaryButtonText,
  primaryButtonUrl,
  secondaryButtonText,
  secondaryButtonUrl,
  backgroundImageUrl,
  nwplusTitle,
  nwplusBody,
  nwplusBodyPre,
  nwplusBodyHighlight,
  nwplusBodySup,
  nwplusSmallLogo,
  abTestOverrides,
}) => {
  const { assignedVariantName, loading } = useExperiment({
    testName: abTestOverrides?.testName || '',
  });
  const [primaryUrl, setPrimaryUrl] = useState();

  const primaryEntityName = `${analyticsBase.entity_names.primary_cta}_${id}`;
  const secondaryEntityName = `${analyticsBase.entity_names.secondary_cta}_${id}`;

  const { trackSegmentTrackEvent } = useAnalytics();

  useEffect(() => {
    const activeVariant = abTestOverrides?.variants.find(
      (variant) => assignedVariantName === variant.variantName,
    );

    const getAbTestPrimaryUrl = () => {
      return activeVariant.variantName === 'control'
        ? activeVariant.url
        : setTrk(activeVariant);
    };

    setPrimaryUrl(activeVariant ? getAbTestPrimaryUrl() : primaryButtonUrl);
  }, [abTestOverrides, assignedVariantName, primaryButtonUrl]);

  if (loading) return null;

  const VERT_COMP_TAB_TEST_ID = EXPERIMENTS.verticalComparisonTabExperiment.id;
  const { VARIANT: VERT_COMP_TAB_VAR } =
    EXPERIMENTS.verticalComparisonTabExperiment.variants;

  const displayNwplusBox = (nwplusTitle && nwplusBody) ?
    <div className={styles.nwplusBox}>
      <span
        className={styles.nwplusBoxIcon}
      >
        <Image
          imageClassName={styles.nwplusSmallLogo}
          className={styles.nwplusSmallLogo}
          backgroundColor="none"
          src={nwplusSmallLogo}
          width="28"
          height="15"
        />
      </span>
      <Text bold>
        <span className={styles.nwplusBoxTitle}>
          {nwplusTitle}
        </span>
      </Text>
      <div className={styles.nwplusBodyContainer}>
        <Text>
          <span className={styles.nwplusBodyPre}>
            {nwplusBodyPre}
          </span>
          <span className={styles.nwplusBodyHighlight}>
            {nwplusBodyHighlight}
          </span>
          <span className={styles.nwplusBody}>
            {nwplusBody}
          </span>
          <span className={styles.nwplusSup}>
            <sup>{nwplusBodySup}</sup>
          </span>
        </Text>
      </div>
    </div> : (
      <div className={classes(
          'padding-top-5--wide',
          'padding-bottom-5--wide',
        )}></div>
    );

  return (
    <Box>
      <Box className={classNames(styles.contentWrapper)}>
        <Box className={styles.contentLeftContainer}>
          <Box className={classNames(styles.contentBox)}>
            <Text className={classes('margin-bottom-2')}>
              <span className={styles.contentSubtitle}>{subtitle}</span>
            </Text>
            <div className={classes('margin-bottom-4')}>
              <Text>{description}</Text>
            </div>
            <div className={styles.buttonWidth}>
              <Button
                primary
                block
                href={primaryUrl}
                component="a"
                onClick={() => {
                  trackInteraction({
                    entityName: primaryEntityName,
                    sectionName: analyticsBase.section_name,
                    action: 'select',
                  });
                  trackSegmentTrackEvent('Element Interaction', {
                    entity_name: primaryEntityName,
                    location:
                      HOMEPAGE_SEGMENT_INSTRUMENTATION.vertical_comparison
                        .location,
                    interaction_type: 'select',
                  });
                }}
              >
                {primaryButtonText}
              </Button>
            </div>
            <div className={styles.buttonRightWidth}>
              <Experiment testName={VERT_COMP_TAB_TEST_ID}>
                <ExperimentControl>
                  <div>
                    <Button
                      secondary
                      block
                      href={secondaryButtonUrl}
                      component="a"
                      onClick={() => {
                        trackInteraction({
                          entityName: secondaryEntityName,
                          sectionName: analyticsBase.section_name,
                          action: 'select',
                        });
                        trackSegmentTrackEvent('Element Interaction', {
                          entity_name: secondaryEntityName,
                          location:
                            HOMEPAGE_SEGMENT_INSTRUMENTATION.vertical_comparison
                              .location,
                          interaction_type: 'select',
                        });
                      }}
                    >
                      {secondaryButtonText}
                    </Button>
                  </div>
                </ExperimentControl>
                <ExperimentVariant name={VERT_COMP_TAB_VAR} />
              </Experiment>
            </div>
          </Box>
          { displayNwplusBox }
        </Box>
        <Box style={{ backgroundImage: `url(${backgroundImageUrl})` }} className={styles.contentBoxBackground} />
      </Box>
    </Box>
  );
};

VerticalComparisonTab.propTypes = {
  id: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  primaryButtonText: PropTypes.string,
  primaryButtonUrl: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  secondaryButtonUrl: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  nwplusTitle: PropTypes.string,
  nwplusBody: PropTypes.string,
  nwplusBodyPre: PropTypes.string,
  nwplusBodyHighlight: PropTypes.string,
  nwplusBodySup: PropTypes.string,
  nwplusSmallLogo: PropTypes.string,
  abTestOverrides: PropTypes.shape({
    testName: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        variantName: PropTypes.string,
        url: PropTypes.string,
        params: PropTypes.shape({
          trk_ab_test: PropTypes.string,
          trk_ab_test_variant: PropTypes.string,
        }),
      }),
    ),
  }),
};

PropTypes.default = {
  abTestOverrides: {
    testName: '',
    variants: [
      {
        variantName: '',
        url: '',
        params: {
          trk_ab_test: '',
          trk_ab_test_variant: '',
        },
      },
    ],
  },
};

VerticalComparisonTab.displayName = 'VerticalComparisonTab';

export default VerticalComparisonTab;
