import appConfig from '@nerdwallet/app-config';

import { VERTICALS } from './constants';

const TABS = [
  VERTICALS.CREDIT_CARD,
  VERTICALS.BANKING,
  VERTICALS.TRAVEL,
  VERTICALS.SMALL_BUSINESS,
  VERTICALS.PERSONAL_LOANS,
  VERTICALS.INSURANCE,
  VERTICALS.MORTGAGES,
  VERTICALS.INVESTING,
  VERTICALS.STUDENT_LOANS,
];

export const verticalComparisonContent = () => ({
  // to break title into two lines, add \n where you want the lines to split
  title: 'Make smarter financial decisions with NerdWallet’s',
  typeModuleText: [
    'side-by-side comparisons',
    'objective ratings and reviews',
    'customized insights',
    'trusted expertise',
  ],
  backgroundImageUrl: `${appConfig.ASSETS_URL}/images/homepage/tab1-background.svg`,
  tabs: TABS,
});

export const byYourSideContent = () => ({
  // to break title into two lines, add \n where you want the lines to split
  title: 'Make smarter financial decisions with the Nerds\nby your side',
  subtitle: '',
  backgroundImageUrl: `${appConfig.ASSETS_URL}/images/homepage/tab1-background.svg`,
  tabs: TABS,
});

export const startTodayContent = () => ({
  // to break title into two lines, add \n where you want the lines to split
  title: 'Start making smarter financial decisions today',
  subtitle: '',
  backgroundImageUrl: `${appConfig.ASSETS_URL}/images/homepage/tab1-background.svg`,
  tabs: TABS,
});

export const compareFindContent = () => ({
  // to break title into two lines, add \n where you want the lines to split
  title: 'Compare and find the smartest financial products\nfor you',
  subtitle: '',
  backgroundImageUrl: `${appConfig.ASSETS_URL}/images/homepage/tab1-background.svg`,
  tabs: TABS,
});

export const notReadyToGoPlus = () => ({
  // to break title into two lines, add \n where you want the lines to split
  title: 'Not ready to go plus?',
  subtitle: 'Our Nerds have plenty of free resources — from comparison tools, calculators, guides, and more — to help you make smarter financial decisions today.',
  backgroundImageUrl: '',
  tabs: TABS,
});
