'use client';
/**
 * Dependencies
 */
import React, { useState } from 'react';

import LottieAnimation from '../components/front-page/lottie-animation/lottie-animation';
import { NerdAIChat } from '@nerdwallet/ai-components';
import { Box } from '@nerdwallet/currency/components/Box';
import {
  Experiment,
  ExperimentControl,
  ExperimentVariant,
} from '@nerdwallet/features';
import { AnalyticsContextProvider } from '@nerdwallet/structured-content/contexts/AnalyticsContext';
import StructuredContent, {
  StructuredContentProvider,
  createClient,
} from '@nerdwallet/structured-content-core';
import dynamic from 'next/dynamic';
import schema from '@nerdwallet/structured-content/schema';

// Local Components
import VerticalComparison from '~/app/components/front-page/vertical-comparison/vertical-comparison';
import VerticalComparisonNoTyping from '~/app/components/front-page/vertical-comparison/vertical-comparison-no-typing';
import CCBest from '~/app/components/front-page/cc-best/cc-best';
import NewsArticles from '~/app/components/front-page/news-articles/news-articles-container/news-articles-container';
import { EXPERIMENTS } from '~/app/experiments';
import HomepageRemoveBannerMobile from '../experiments/homepage-remove-banner-mobile';

// Other Local Dependencies
import {
  verticalComparisonContent,
  byYourSideContent,
  startTodayContent,
  compareFindContent,
  notReadyToGoPlus,
  ccBestContent,
  campaignHighlightContent,
  announcementBannerContent,
  nwPlusHeroContent,
  nwPlusAnimations,
} from '~/app/lib/front-page-content';
import components from '~/app/lib/nerdai-sc-components';

import styles from './front-page.module.scss';
import AnnouncementBanner from '~/app/components/front-page/announcement-banner';
import SecondaryCampaignModuleContainer from '~/app/components/front-page/secondary-campaign-module/secondary-campaign-module-container';
import SecondaryCampaignModuleContent from '~/app/lib/front-page-content-src/secondary-campaign-module-content';
import CampaignHighlight from '~/app/components/front-page/campaign-highlight/campaign-highlight';
import useIsAuthenticated from '~/app/hooks/isAuthenticated';
import getAuthValues from '~/app/lib/front-page-content-src/getAuthValues';
// @ts-ignore
import initBaseStyles from '@nerdwallet/base-styles/init';
import { initBrowserEvents } from '@nerdwallet/analytics';
import SegmentTracking, {
  SegmentTrackingContext,
} from '~/app/lib/segment/SegmentTracking';
import BounceTracking from '../components/bounce-tracking';
import { useInitializeGtm } from '../lib/initializeGtm';
import { getPageTaxonomy } from '../lib/page-taxonomy';
import { usePathname } from 'next/navigation';
import { NwPlusHero } from '~/app/components/front-page/nw-plus-hero/nw-plus-hero';

if (typeof window !== 'undefined') {
  initBaseStyles();
  initBrowserEvents();
}

const SHOW_CHATBOT = EXPERIMENTS.chatbotVisibleExperiment.id;
const { VARIANT: CHATBOT_IS_VISIBLE } =
  EXPERIMENTS.chatbotVisibleExperiment.variants;
const HEADLINE = EXPERIMENTS.headlineExperiment.id;
const {
  VARIANT_SIDE: BY_YOUR_SIDE,
  VARIANT_TODAY: START_TODAY,
  VARIANT_COMPARE: COMPARE_FIND,
} = EXPERIMENTS.headlineExperiment.variants;

const FrontPage = () => {
  const [showAnnouncementBanner, setShowAnnouncementBanner] = useState(true);
  const isAuthenticated = useIsAuthenticated();
  const client = createClient({
    components,
    schema,
    codesplittingFunction: (asyncImport, exportName) =>
      dynamic(() =>
        asyncImport.then((mod) => ({
          default: mod[exportName],
        })),
      ),
  });
  useInitializeGtm({ pageTaxonomy: getPageTaxonomy(usePathname()) ?? [] });

  return (
    <React.Fragment>
      <BounceTracking />
      <NwPlusHero {...nwPlusHeroContent} />
      <div className={styles.lottieContainer15}>
        <div className={styles.lottieBurst15}>
          <LottieAnimation burst={nwPlusAnimations.burst15} />
        </div>
      </div>
      <div className={styles.lottieContainer12}>
        <div className={styles.lottieBurst12}>
          <LottieAnimation burst={nwPlusAnimations.burst12} />
        </div>
      </div>
      <SegmentTracking segmentPageName="homepage">
        <Box marginTop={3}>
          {showAnnouncementBanner && (
            <HomepageRemoveBannerMobile>
              <AnnouncementBanner
                handleOnClose={() => setShowAnnouncementBanner(false)}
                {...getAuthValues(announcementBannerContent, isAuthenticated)}
              />
            </HomepageRemoveBannerMobile>
          )}
          <Experiment testName={HEADLINE}>
            <ExperimentControl>
              <VerticalComparison {...verticalComparisonContent()} />
            </ExperimentControl>
            <ExperimentVariant name={BY_YOUR_SIDE}>
              <VerticalComparisonNoTyping {...notReadyToGoPlus()} />
            </ExperimentVariant>
            <ExperimentVariant name={START_TODAY}>
              <VerticalComparisonNoTyping {...startTodayContent()} />
            </ExperimentVariant>
            <ExperimentVariant name={COMPARE_FIND}>
              <VerticalComparisonNoTyping {...compareFindContent()} />
            </ExperimentVariant>
          </Experiment>
        </Box>
        <SegmentTrackingContext.Consumer>
          {({
            analytics: segmentAnalytics,
            deployableName,
            deployableVersion,
            pageViewId,
          }) => (
            <Experiment testName={SHOW_CHATBOT}>
              <ExperimentVariant name={CHATBOT_IS_VISIBLE}>
                <Box className={styles.contentWrapper}>
                  <StructuredContentProvider client={client}>
                    <AnalyticsContextProvider
                      value={{
                        sectionName: 'chatbot_nerdai',
                        segmentAttributes: {
                          pageViewId,
                          segmentInstance: segmentAnalytics,
                        },
                      }}
                    >
                      <NerdAIChat
                        StructuredContentComponent={StructuredContent}
                        inputPlaceholder="Ask NerdWallet AI..."
                        variant="home"
                        segmentAnalytics={{
                          instance: segmentAnalytics,
                          deployableName,
                          deployableVersion,
                          pageViewId,
                        }}
                      />
                    </AnalyticsContextProvider>
                  </StructuredContentProvider>
                </Box>
              </ExperimentVariant>
            </Experiment>
          )}
        </SegmentTrackingContext.Consumer>
        <div className={styles.lottieContainer16}>
          <div className={styles.lottieBurst16}>
            <LottieAnimation burst={nwPlusAnimations.burst16} />
          </div>
        </div>
        <div className={styles.lottieContainer14}>
          <div className={styles.lottieBurst14}>
            <LottieAnimation burst={nwPlusAnimations.burst14} />
          </div>
        </div>
        <Box>
          <NewsArticles />
        </Box>
        <CampaignHighlight {...campaignHighlightContent} />
        <div className={styles.lottieContainer4}>
          <div className={styles.lottieBurst4}>
            <LottieAnimation burst={nwPlusAnimations.burst4} />
          </div>
        </div>
        <div className={styles.lottieContainer17}>
          <div className={styles.lottieBurst17}>
            <LottieAnimation burst={nwPlusAnimations.burst17} />
          </div>
        </div>
        <CCBest {...ccBestContent} />
        <Box className={styles.footerDisclaimer}>
          <sup>1</sup>All eligible products require partner approval. See
          partner terms and conditions before applying.
        </Box>
      </SegmentTracking>
    </React.Fragment>
  );
};

export default FrontPage;
