import { HOMEPAGE as analyticsBase } from '../analytics/analytics-constants';

export interface CampaignHighlight {
  title: string;
  subtitle: string;
  titleEnd: string;
  backgroundColor: string;
  ctaButtonText: string;
  ctaLinkUrl: string;
  titlePreColor: string;
  titleEndColor: string;
  campaignHighlightFooterCopy: string;
  campaignHighlightFooterCopyColor: string;
  visualBackgroundImageDesktopUrl: string;
  visualBackgroundImageMidUrl: string;
  visualBackgroundImageTabletUrl: string;
  visualBackgroundImageMobileUrl: string;
  visualBackgroundImageAlt: string;
  nwplusFullLogo: string;
  nwplusFullLogoAlt: string;
  nwplusSmallLogo: string;
  trkQueryParam: string;
}

const content: CampaignHighlight = {
  title: 'Got a question?',
  subtitle:
    'With a NerdWallet+ membership, getting an answer is easy. You can just ask the Nerds —  journalists who have reviewed 1,300+ financial products — and get a reply within two business days.',
  titleEnd: '',
  backgroundColor: '#E8FDAC',
  ctaButtonText: 'ASK YOUR QUESTION',
  ctaLinkUrl: 'lp/nerdwallet-plus-main',
  titlePreColor: '#00482F',
  titleEndColor: '#00482F',
  campaignHighlightFooterCopy: '',
  campaignHighlightFooterCopyColor: '',
  visualBackgroundImageDesktopUrl: `/nwplus_campaign_rewards_desktop-v2.png`,
  visualBackgroundImageMidUrl: `/nwplus_campaign_rewards_mid.png`,
  visualBackgroundImageTabletUrl: `/nwplus_campaign_rewards_tablet.png`,
  visualBackgroundImageMobileUrl: `/nwplus_campaign_rewards_mobile-v2.png`,
  visualBackgroundImageAlt: 'A Nerd greeting Nerdwallet+ user with: Hello, Teresa!',
  nwplusFullLogo: `/nwplus_full_logo.png`,
  nwplusFullLogoAlt: 'NerdWallet Plus Logo',
  nwplusSmallLogo: `/nw_plus_assets/nw_plus_small_logo_dark.png`,
  trkQueryParam: analyticsBase.entity_names.campaign, // used to append to the URL when the button is clicked,
};

export default content;
