import classNames from 'classnames';
import Image from 'next/image';
import { Button, Link } from '@nerdwallet/currency';
import styles from './nw-plus-hero.module.scss';
import useAnalytics from '~/app/lib/segment/useAnalytics';
import { HOMEPAGE_SEGMENT_INSTRUMENTATION } from '~/app/lib/analytics/analytics-constants';

interface Props {
  showStickyBanner: boolean;
  nwplusSmallLogo: string;
  nwplusFullLogoAlt: string;
  stickyBannerText: string;
  stickyBannerCtaText: string;
  ctaLinkUrl: string;
  trkQueryParamSticky: string;
}

export function NwPlusStickyBanner({
  showStickyBanner,
  nwplusSmallLogo,
  nwplusFullLogoAlt,
  stickyBannerText,
  stickyBannerCtaText,
  ctaLinkUrl,
  trkQueryParamSticky,
}: Props) {
  const { trackSegmentTrackEvent } = useAnalytics();
  const handleCampaignLinkClick = () => {
    trackSegmentTrackEvent(
      'Element Interaction',
      HOMEPAGE_SEGMENT_INSTRUMENTATION.sticky_banner,
    );
  };

  return (
    <div
      className={classNames([
        styles.nwPlusStickyBanner,
        { [styles.showStickyBanner]: showStickyBanner },
      ])}
    >
      <div>
        <div className={styles.nwPlusStickyBannerTextContainer}>
          <Link
            href={`${ctaLinkUrl}?trk=${trkQueryParamSticky}`}
            onClick={handleCampaignLinkClick}
          >
            <Image
              className={styles.nwPlusStickyBannerIcon}
              src={nwplusSmallLogo}
              alt={nwplusFullLogoAlt}
              width="43"
              height="24"
            />
          </Link>
          <h3 className={styles.nwPlusStickyBannerText}>{stickyBannerText}</h3>
        </div>
      </div>
      <Button
        href={`${ctaLinkUrl}?trk=${trkQueryParamSticky}`}
        primary
        className={styles.nwPlusStickyBannerCta}
        onClick={handleCampaignLinkClick}
      >
        {stickyBannerCtaText}
      </Button>
    </div>
  );
}
