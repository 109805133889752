import Image from 'next/image';
// @ts-ignore
import Carousel from '@nerdwallet/react-carousel';
import _ from 'lodash';
import styles from './nw-plus-hero.module.scss';
import { NwPlusCard } from '~/app/lib/front-page-content-src/nw-plus-hero-content';

const NwPlusHeroCarouselCards = ({
  icon,
  title,
  subtitle,
}: {
  icon: string;
  title: string;
  subtitle: string;
}) => {
  return (
    <div className={styles.nwPlusCardsItem}>
      <Image width="100" height="70" src={icon} alt={title} />
      <h3 className={styles.nwPlusCardsItemTitle}>{title}</h3>
      <p>{subtitle}</p>
    </div>
  );
};

export const NwPlusHeroCarousel = ({ cards }: { cards: NwPlusCard[] }) => {
  const renderedTiles = _.map(cards, (tile, nav) => (
    <NwPlusHeroCarouselCards {...tile} key={`item-${nav}`} />
  ));

  return (
    <div>
      <div className={styles.nwPlusCarouselContainer}>
        {cards.map((card) => (
          <NwPlusHeroCarouselCards {...card} key={card.title} />
        ))}
      </div>
      <div className={styles.carouselContainer}>
        <Carousel
          id="nwplus-carousel"
          entityName="nwplus-hero-carousel"
          className={styles.carousel}
          decorateNav={false}
          enableDotNavigation
          arrows={false}
          dots
          slidesToShow="1.3"
          suppressHydrationWarning
        >
          {renderedTiles}
        </Carousel>
      </div>
    </div>
  );
};
